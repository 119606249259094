import { ref, onUnmounted, computed, watch } from "vue";
import { usePage } from "@inertiajs/vue3";
import Echo from "laravel-echo";

// Have to do this to prevent the linter from
// dropping the import lol.
Echo;

/** @type { Echo } */
let echo = null;

if (import.meta.env.NODE_ENV === "test") {
  class EchoMock {
    private() {
      return this;
    }

    join() {
      return {
        here: () => this,
        joining: () => this,
        leaving: () => this,
        listen: () => this,
      };
    }

    listen() {
      return this;
    }

    leaveChannel() {
      return this;
    }
  }

  echo = new EchoMock();
}

const organisationChannel = computed(() => {
  const organisation = usePage().props?.organisation;

  if (!organisation) {
    return null;
  }

  return `Organisation.${organisation.id}`;
});

watch(organisationChannel, (_, oldOrganisationChannel) => {
  if (oldOrganisationChannel) {
    echo.leaveChannel(`Presence.${oldOrganisationChannel}`);
  }
});

function setEcho(newEcho) {
  echo = newEcho;
}

function usePresence(channelName) {
  const users = ref([]);
  let channel = null;

  watch(channelName, () => {
    if (!channelName.value) return;

    channel = echo
      .join(`Presence.${channelName.value}`)
      .here((here) => (users.value = here))
      .joining((join) => users.value.push(join))
      .leaving(
        (leave) => (users.value = users.value.filter((u) => u.id !== leave.id))
      );
  });

  return {
    users,
    channel,
  };
}

const useOrganisationPresence = usePresence(organisationChannel);

/**
 * @param {(data: unknown, stopWorking: () => void) => void} callback
 * @param {{initialState: boolean, event?: string, channel?: string}} options
 */
function useWorking(callback, options) {
  const working = ref(options.initialState || false);

  echo
    .private(options.channel)
    .listen(options.event, (data) =>
      callback(data, () => (working.value = false))
    );

  onUnmounted(() => echo.private(options.channel).stopListening(options.event));

  return {
    working,
  };
}

export {
  setEcho,
  useOrganisationPresence,
  useWorking,
  echo,
  organisationChannel,
};
